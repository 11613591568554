@import "../../styles/variable.module.scss";
.notFound_details {
  .img {
    max-width: 200px;
    margin: 0 auto;
  }
  .head {
    @include headingFont(font-family, "DM Sans", "Almarai");
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 56px;
    margin-bottom: 40px;
    margin-top: 16px;
    @include medium_device {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }
  .btn {
    [class*="btn"] {
      margin-left: 16px;
      @include medium_device {
        margin-left: 10px;
      }
      @include mobile_device {
        margin-left: 0;
        width: 100%;
        & + [class*="btn"] {
          margin-top: 16px;
        }
      }
    }
  }
}
[dir="rtl"] {
  .notFound_details {
    .btn {
      [class*="btn"] {
        margin-right: 16px;
        margin-left: 0;
        @include medium_device {
          margin-right: 10px;
        }
        @include mobile_device {
          margin-right: 0;
        }
      }
    }
  }
}
