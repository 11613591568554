.loader {
  position: relative;
  width: 30px;
  height: 30px;
  transform: scale(0.5);
  // margin-left: 5px;
  div {
    &:first-child {
      left: 3px;
      top: 12px;
      animation-delay: 0.39s;
      -o-animation-delay: 0.39s;
      -ms-animation-delay: 0.39s;
      -webkit-animation-delay: 0.39s;
      -moz-animation-delay: 0.39s;
      transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
    }
    &:nth-child(2) {
      left: 6px;
      top: 4px;
      animation-delay: 0.52s;
      -o-animation-delay: 0.52s;
      -ms-animation-delay: 0.52s;
      -webkit-animation-delay: 0.52s;
      -moz-animation-delay: 0.52s;
      transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
    }
    &:nth-child(3) {
      left: 15px;
      top: 1px;
      animation-delay: 0.65s;
      -o-animation-delay: 0.65s;
      -ms-animation-delay: 0.65s;
      -webkit-animation-delay: 0.65s;
      -moz-animation-delay: 0.65s;
      transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
    }
    &:nth-child(4) {
      right: 6px;
      top: 4px;
      animation-delay: 0.78s;
      -o-animation-delay: 0.78s;
      -ms-animation-delay: 0.78s;
      -webkit-animation-delay: 0.78s;
      -moz-animation-delay: 0.78s;
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
    }
    &:nth-child(5) {
      right: 3px;
      top: 12px;
      animation-delay: 0.91s;
      -o-animation-delay: 0.91s;
      -ms-animation-delay: 0.91s;
      -webkit-animation-delay: 0.91s;
      -moz-animation-delay: 0.91s;
      transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
    }
    &:nth-child(6) {
      right: 6px;
      bottom: 4px;
      animation-delay: 1.04s;
      -o-animation-delay: 1.04s;
      -ms-animation-delay: 1.04s;
      -webkit-animation-delay: 1.04s;
      -moz-animation-delay: 1.04s;
      transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
    }
    &:nth-child(7) {
      bottom: 1px;
      left: 15px;
      animation-delay: 1.17s;
      -o-animation-delay: 1.17s;
      -ms-animation-delay: 1.17s;
      -webkit-animation-delay: 1.17s;
      -moz-animation-delay: 1.17s;
      transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
    }
    &:nth-child(8) {
      left: 6px;
      bottom: 4px;
      animation-delay: 1.3s;
      -o-animation-delay: 1.3s;
      -ms-animation-delay: 1.3s;
      -webkit-animation-delay: 1.3s;
      -moz-animation-delay: 1.3s;
      transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
    }
  }
  .loaderBorder {
    position: absolute;
    background-color: #ffffff;
    width: 2px;
    height: 7px;
    border-radius: 8px 8px 0 0;
    -o-border-radius: 8px 8px 0 0;
    -ms-border-radius: 8px 8px 0 0;
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    transform: scale(0.4);
    -o-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    animation-name: fadeG;
    -o-animation-name: fadeG;
    -ms-animation-name: fadeG;
    -webkit-animation-name: fadeG;
    -moz-animation-name: fadeG;
    animation-duration: 1.04s;
    -o-animation-duration: 1.04s;
    -ms-animation-duration: 1.04s;
    -webkit-animation-duration: 1.04s;
    -moz-animation-duration: 1.04s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    @-webkit-keyframes fadeG {
      0% {
        background-color: #000000;
      }
      100% {
        background-color: #ffffff;
      }
    }
    @keyframes fadeG {
      0% {
        background-color: #000000;
      }
      100% {
        background-color: #ffffff;
      }
    }
  }
}
[dir="rtl"] {
  .loader {
    margin-right: 5px;
    margin-left: 0;
  }
}