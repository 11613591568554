@import "../../../styles/variable.module.scss";
.p0 {
  @include large_device {
    [class^="modal"] {
      max-width: 600px;
    }
  }
  [class*="-content"] {
    padding: 0;
    border-radius: 16px;
    .earlyAccessModal {
      & > div {
        width: 50%;
        @include medium_device {
          width: 100%;
        }
      }
      .imgBlock {
        background: #d2e6ff;
        border-radius: 16px 0 0 16px;
        @include medium_device {
          max-height: 200px;
          padding: 20px;
          border-radius: 16px 16px 0 0;
          & > span {
            width: 160px !important;
          }
        }
      }
      .earlyAccessDetails {
        padding: 32px;
        [class*="title"] {
          margin-right: 45px;
        }
        @include medium_device {
          [class$="h4"] {
            font-size: 28px;
          }
        }
        .formGroup {
          margin-bottom: 32px;
        }
      }
    }
  }
}
.subDetails {
  .submitting {
    .img {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      -webkit-transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
    }
  }
  &[class*="show"] {
    .submitting {
      .img {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}

.p0,
.subDetails {
  @include medium_device {
    [class$="h4"] {
      & > div {
        top: -15px;
        background-color: #fcfcfd !important;
        right: -15px;
        width: 35px;
        height: 35px;
        background-size: 12px;
      }
    }
  }
}
[dir="rtl"] {
  .p0 {
    [class*="-content"] {
      .earlyAccessModal {
        [class*="title"] {
          margin-left: 45px;
          margin-right: 0;
        }
        .imgBlock {
          border-radius: 0 16px 16px 0;
          @include mobile_device {
            border-radius: 16px 16px 0 0;
          }
        }
      }
    }
  }
}
