@import "../../../styles/variable.module.scss";
.discoverIndex2 {
  [class*="selectMenu"] {
    width: 256px;
    margin-right: 0;
    margin-left: auto;
    @include mobile_device {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  .loadmoreBtn {
    @include medium_device {
      display: none;
    }
  }
  .discover__slider {
    [class*="-next"],
    [class*="-prev"] {
      @include medium_device {
        bottom: auto;
        top: -100px;
      }
    }
    [class*="-prev"] {
      @include medium_device {
        left: auto;
      }
    }
    [class*="-list"] {
      margin: 0;
      & > div {
        & > div {
          .discover__card {
            text-align: center;
          }
        }
      }
    }
  }
  .hot__title {
    margin-bottom: 48px;
    @include medium_device {
      margin-bottom: 20px;
      font-size: 32px;
    }
    @include mobile_device {
      font-size: 24px;
    }
  }
  .nftRows{
    @media only screen and (min-width: 1200px){
      margin: 0 -15px;
    }
    @include medium_device{
      margin: 0 -8px 15px;
      > div{
        padding: 0 8px;
      }
    }
  }
  [class$=grid-column-4]{
    @include medium_device{
      grid-template-columns: auto auto;
      gap: 16px;
    }
  }
}

.AddedselectMenu {
  width: 180px;
  @include large_device {
    width: 250px;
  }
  @include mobile_device {
    width: 100%;
  }
}
.customBTab {
  width: auto;
}
.allItems {
  @include large_device {
    width: calc(100% - 400px);
  }
  @include medium_device {
    width: calc(100% - 300px);
    margin: 0 10px;
  }
  @include mobile_device {
    width: 100%;
    margin: 0;
  }
}
.customSection {
  padding: 0;
  .accordion {
    margin-top: 0px;
  }
}
.accordion {
  margin-bottom: 32px;
  margin-top: 40px;
  .cardHeader {
    padding-bottom: 20px;
  }
  .filterBtn {
    width: 116px;
    padding: 0 20px;
  }
  .cardBody {
    margin-top: 32px;
  }
  .filterBtn {
    @include medium_device {
      width: 100%;
    }
  }
}
.discover__slider {
  [class*="-next"],
  [class*="-prev"] {
    top: auto;
    bottom: -60px;
  }
  [class*="-prev"] {
    left: calc(50% - 50px);
  }
  [class*="-next"] {
    right: calc(50% - 50px);
  }
  [class$="-list"] {
    margin: 0 -20px;
  }
  .discover__card {
    & > div {
      & > div {
        & > a {
          & > div:last-child {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
.loadmoreBtn {
  margin-top: 30px;
  @include medium_device {
    margin-top: 20px;
  }
  &:hover {
    & > div {
      & > span {
        & > span {
          background-color: var(--background-color);
        }
      }
    }
  }
}

[dir="rtl"] {
  .discoverIndex2 {
    [class*="selectMenu"] {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
